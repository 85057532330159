html,
body {
	padding: 0;
	margin: 0;
	font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

img {
	max-width: 100%;
	height: auto;
}

h1,
h2,
p,
ul {
	margin: 0;
}

ul {
	padding: 0;
	list-style: none;
}

button {
	padding: 0.5rem 1rem;
	font-weight: bold;
}

/* fonts default */
.fs-8rem {
	font-size: 0.5rem;
}

.fs-9rem {
	font-size: 0.5625rem;
}

.fs-10rem {
	font-size: 0.625rem;
}

.fs-11rem {
	font-size: 0.688rem;
}

.fs-12rem {
	font-size: 0.75rem;
}

.fs-13rem {
	font-size: 0.8125rem;
}

.fs-14rem {
	font-size: 0.875rem;
}

.fs-16rem {
	font-size: 1rem;
}

.fs-20rem{
	font-size: 1.2rem;
}

.fs-22rem {
	font-size: 1.375rem;
}

.fs-24rem {
	font-size: 1.5rem;
}

.fs-26rem {
	font-size: 1.625rem;
}

.fs-28rem{
	font-size:28px;
}

.fs-30rem{
	font-size:1.875rem;
}

.fs-34rem {
	font-size: 2.125rem;
}

.fs-36rem {
	font-size: 2.25rem;
}

.fs-48rem {
	font-size: 3rem;
}

.fs-50rem{
	font-size: 4rem;
}

/* Gradient */

.gradient1 {
	background: #8fcae6;
	/* background: linear-gradient(90deg, rgba(133,128,217,1) 0%, rgba(0,212,255,1) 100%); */
}

.gradient2 {
	background: #229ebd;
	/* background: linear-gradient(90deg, rgba(128,217,159,1) 0%, rgba(201,172,0,1) 100%); */
}

.gradient3 {
	background: #feba01;
	/* background: linear-gradient(90deg, rgba(247,196,85,1) 0%, rgba(100,171,217,1) 100%); */
}

.gradient4 {
	background: #023148;
	/* background: linear-gradient(90deg, rgba(85,132,247,1) 0%, rgba(106,217,100,1) 100%); */
}

.gradient5 {
	background: #fc8502;
	/* background: linear-gradient(90deg, rgba(247,153,85,1) 0%, rgba(217,100,185,1) 100%); */
}

.cursor-pointer {
	cursor: pointer;
}